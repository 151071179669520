import React, { createContext, useContext, useState, useEffect } from "react"
import { onAuthStateChanged } from "firebase/auth"
import { auth } from "../services/firebase"
import { getComments } from "../services/comments"
import { getPost } from "../services/posts"

const AuthContext = createContext({})

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [postDetails, setPostDetails] = useState({})
  const [postComments, setPostComments] = useState([])

  const getPostComments = async postId => {
    const comments = await getComments(postId)
    setPostComments(comments)
  }

  const getPostDetails = async postId => {
    const postDetails = await getPost(postId)
    setPostDetails(postDetails)
  }

  useEffect(() => {
    onAuthStateChanged(auth, async(authInfo) => setUser(authInfo))

    const likedPosts = JSON.parse(localStorage.getItem('likedPosts'))
    if(!likedPosts) localStorage.setItem('likedPosts', JSON.stringify([]))
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        postComments,
        getPostComments,
        postDetails,
        getPostDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => useContext(AuthContext)

export default useAuthContext
