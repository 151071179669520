import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"

const config = {
  apiKey: "AIzaSyCm0RUulTGTwckqQiGMqPjNLGx0C0oUfMo",

  authDomain: "pluto-astrology.firebaseapp.com",

  projectId: "pluto-astrology",

  storageBucket: "pluto-astrology.appspot.com",

  messagingSenderId: "117247841252",

  appId: "1:117247841252:web:bafc605ec0906206fb2df1",

  measurementId: "G-2SSVQ3F6TR",
}

const app = initializeApp(config)

export const db = getFirestore(app)
export const auth = getAuth()
