exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-counseling-js": () => import("./../../../src/pages/counseling.js" /* webpackChunkName: "component---src-pages-counseling-js" */),
  "component---src-pages-evolutionary-astrology-abbreviations-js": () => import("./../../../src/pages/evolutionary-astrology/abbreviations.js" /* webpackChunkName: "component---src-pages-evolutionary-astrology-abbreviations-js" */),
  "component---src-pages-evolutionary-astrology-js": () => import("./../../../src/pages/evolutionary-astrology.js" /* webpackChunkName: "component---src-pages-evolutionary-astrology-js" */),
  "component---src-pages-evolutionary-astrology-planetary-sequence-js": () => import("./../../../src/pages/evolutionary-astrology/planetary-sequence.js" /* webpackChunkName: "component---src-pages-evolutionary-astrology-planetary-sequence-js" */),
  "component---src-pages-evolutionary-astrology-resources-js": () => import("./../../../src/pages/evolutionary-astrology/resources.js" /* webpackChunkName: "component---src-pages-evolutionary-astrology-resources-js" */),
  "component---src-pages-evolutionary-astrology-what-is-ea-js": () => import("./../../../src/pages/evolutionary-astrology/what-is-ea.js" /* webpackChunkName: "component---src-pages-evolutionary-astrology-what-is-ea-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

