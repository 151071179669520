import { getDoc, setDoc, doc } from "firebase/firestore"
import { db } from "./firebase"

export const getPost = async postId => {
  const docRef = doc(db, "posts", `${postId}`)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    return {}
  }
}

export const createOrUpdatePost = async ({ id, ...postInfo }) => {
  try {
    const docRef = await setDoc(doc(db, "posts", `${id}`), postInfo)
    return docRef
  } catch (e) {
    return { message: "error while updating post info" }
  }
}
