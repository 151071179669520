import React from "react"
import { StyledEngineProvider } from "@mui/material/styles"
import { AuthContextProvider } from "./src/context/AuthContext"
import "./src/services/firebase"

import "./src/styles/global.css"
import "./src/templates/blog.scss"

// export function shouldUpdateScroll() {
//   return false
// }

export const wrapRootElement = ({ element }) => (
  <AuthContextProvider>
    <StyledEngineProvider injectFirst>{element}</StyledEngineProvider>
  </AuthContextProvider>
)