import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  addDoc,
} from "firebase/firestore"
import { db } from "./firebase"

export const getComments = async postId => {
  if (!postId) return []

  const commentsQuery = query(
    collection(db, "comments"),
    where("postId", "==", postId),
    orderBy("createdAt", "desc")
  )
  const querySnapshot = await getDocs(commentsQuery)

  const commentList = []
  querySnapshot.forEach(doc => commentList.push({ id: doc.id, ...doc.data() }))

  return commentList
}

export const addComment = async commentInfo => {
  try {
    const docRef = await addDoc(collection(db, "comments"), commentInfo)
    return docRef
  } catch (e) {
    return { message: "error while posting new comment" }
  }
}
